<template>
  <el-dialog
      title="用户选择"
      :visible.sync="dialogVisible"
      width="800px"
      class="dialogTable"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="handleClose">
    <el-form size="small" :inline="true" ref="searchForm" :model="searchForm"
             label-width="100px">
      <el-form-item prop="phone" label="手机号">
        <el-input v-model="searchForm.phone" placeholder="请输入手机号" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="getDataList(1)"
                   icon="el-icon-search">
          查询
        </el-button>
        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="dataList"
        size="small"
        v-loading="loading"
        ref="multipleTable"
        height="calc(100vh - 385px)"
        class="table"
        :row-key="'id'"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column prop="image" label="头像" width="60">
        <template slot-scope="scope">
          <el-image
              style="width: 35px; height: 35px"
              :src="scope.row.image"
              :fit="'fill'"
              :preview-src-list="[scope.row.image]"
          >
            <div slot="error" class="image-slot">
              <el-image
                  style="width: 35px; height: 35px"
                  :src="require('@/assets/img/defaultAvatar.png')"
                  :fit="'fill'"
              ></el-image>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
      <el-table-column prop="email" label="邮箱" show-overflow-tooltip></el-table-column>
    </el-table>
    <div class="text_center" style="margin-top: 15px">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <div slot="footer" class="text_center">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button size="small" type="primary" @click="saveSelectUser()" v-no-more-click>保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "addUserTable",
  data() {
    return {
      dialogVisible: false,
      searchForm: {
        phone: '',
      },
      dataList: [],
      dataListSelect: [],
      loading: false,
      current: 1,
      size: 10,
      total: 0,
    }
  },

  methods: {
    init() {
      this.dialogVisible = true
      this.$nextTick(()=>{
        this.getDataList(1)
      })
    },

    getDataList(type) {
      if (type == 1) {
        this.current = 1
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      this.$axios(this.api.auth.recodesettingListAddUser, {
        ...this.searchForm,
        current:this.current,
        size:this.size,
      }, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    resetting() {
      this.$refs.searchForm.resetFields()
      this.getDataList(1)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },

    //保存
    saveSelectUser(){
      if (this.dataListSelect.length == 0){
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this.dataListSelect.map(item=>{
        return item.id
      })
      this.$axios(this.api.auth.recodesettingSave, ids, 'post').then((res) => {
        if (res.status) {
          this.$message.success('保存用户成功')
          this.handleClose()
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    handleClose() {
      this.$emit('refresh')
      this.dialogVisible = false;
    },
  },
}
</script>

<style scoped>

</style>